<template>
  <div>
    <b-button
      variant="primary-custom"
      @click="$router.push(`/taxas_de_transferência/${model.unity_id}`)"
    >
      <fa-icon icon="arrow-left" class="mr-2"></fa-icon> Voltar
    </b-button>
    <Card>
      <span class="text-muted mt-3">Taxa de Transferencia</span>
      <hr />
      <b-row>
        <b-col>
          <FormSelect
            label="Tipo"
            :selectOptions="optionsType"
            v-model="model.type"
            :ready="ready"
          />
        </b-col>
        <b-col>
          <FormInput
            type="money"
            :prefix="model.type == 'valor' ? 'R$' : '%'"
            label="Valor"
            v-model="model.amount"
            :ready="ready"
          />
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col>
          <FormInput
            type="money"
            :prefix="'R$'"
            label="Limite mínimo para transferência"
            v-model="model.rescue_limit"
            :ready="ready"
          />
        </b-col>
        <b-col>
          <FormDatepicker
            label="Data Validade"
            v-model="model.expiration_date"
            :ready="ready"
          />
        </b-col>
      </b-row>
      <hr />
      <div class="d-flex justify-content-end mb-3">
        <b-button @click="update_model()">Alterar</b-button>
      </div>
    </Card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ready: false,
      model: {
        unity_id: "",
        type: "",
        amount: "",
        rescue_limit: "",
        expiration_date: "",
        active: "1",
      },
    };
  },
  computed: {
    optionsType() {
      return [
        { text: "Percentual", value: "percentual" },
        { text: "Valor", value: "valor" },
      ];
    },
  },
  methods: {
    async get_unity_transfer_rate() {
      const response = await this.$store.dispatch("http_get", {
        url: `/unity/${this.model.unity_id}/transfer-rate`,
      });
      return response;
    },
    async update_model() {
      this.model.rescue_limit = this.model.rescue_limit.toFixed(2);
      await this.$store.dispatch("http_put", {
        url: `/unity/transfer-rate`,
        data: this.model,
        redirect:`/taxas_de_transferencia/${this.model.unity_id}`
      });
    },
  },
  async mounted() {
    this.model.unity_id = this.$route.params.unityId;
    if (this.model.unity_id) {
      const response = await this.get_unity_transfer_rate();
      console.log(response);
      this.model = response.data[0];
    }
    this.ready = true;
  },
};
</script>

<style></style>
